const developawsexports = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.VUE_APP_AWS_PROJECT_REGION,
    userPoolId: process.env.VUE_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID
  },
  authenticationFlowType: "CUSTOM_AUTH"
}

export default developawsexports
