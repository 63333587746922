<template>
  <div class="bp-cart-alert">
    <div
      class="bp-cart-alert__holder"
      :class="{ 'bp-cart-alert__holder--active': alertActive }"
    >
      <div class="bp-cart-alert__background"></div>
      <div class="bp-cart-alert__content flex--row-center-center">
        <div class="bp-cart-alert__message text-heading-5 text--clr-black">
          {{ getAlertMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, /* mapGetters, */ mapMutations } from "vuex"

let alertTimeout
let alertDuration = 2000

export default {
  // props: {
  //   active: Boolean
  // },

  data: () => ({
    displayQuantityDelta: 0
  }),

  created() {
    this.uiElement_manifest = [
      "cart_alert_item_single",
      "cart_alert_item_multiple",
      "cart_alert_added"
    ]
  },

  methods: {
    ...mapMutations("cart", ["setQuantityDelta", "setAlertActive"]),

    triggerAlert() {
      clearTimeout(alertTimeout)
      // quantityDelta should already be set in store, use it to define display value
      this.displayQuantityDelta = this.quantityDelta
      // set the  to active
      this.setAlertActive(true)
      // and start a countdown timer to hide the alert and reset
      alertTimeout = setTimeout(() => {
        this.reset()
      }, alertDuration)
    },

    reset() {
      // hide the alert
      // reset quantityDelta to 0 for the next time we trigger it
      this.setQuantityDelta(0)
      this.setAlertActive(false)
    }
  },

  computed: {
    ...mapState("cart", ["alertActive", "quantityDelta"]),

    getAlertMessage() {
      return `${this.displayQuantityDelta} ${this.UI_ELEMENT(
        "cart_alert_" +
          (this.displayQuantityDelta > 1 ? "item_multiple" : "item_single")
      )} ${this.UI_ELEMENT("cart_alert_added")}`
    }
  },

  watch: {
    quantityDelta(newVal, oldVal) {
      if (newVal > oldVal) {
        this.triggerAlert()
      }
    }
  }
}
</script>

<style lang="scss">
.bp-cart-alert {
  width: 100%;
  position: absolute;
  top: 100%;
  height: $cart-alert-height-default;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    height: $cart-alert-height-md;
  }

  &__holder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -100%;
    transition: top $banner-animation-speed $banner-animation-ease;

    &--active {
      top: 0;
    }
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $bleach-lime;
  }

  &__content {
    position: absolute;
    width: 100%;
    max-width: $bleach-site-max-width;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }
}
</style>
